.searchForm {
    @apply w-full max-lg:bg-white/[.6] bg-white p-3 lg:p-4 lg:border lg:border-border rounded-lg flex items-center justify-start;
    .searchIcon {
        @apply mr-3;
    }
    .filterIcon {
        @apply ml-3;
    }
    .searchInput {
        background-color: transparent;
        @apply flex-1 focus:outline-none;
    }
}

.rubriquesFilters {
    @apply mt-4;
    button {
        @apply text-[15px] w-fit mt-2 mr-2 rounded-3xl max-lg:bg-white/[.6] bg-white lg:border lg:border-border text-gray py-2 px-3;
        &.active {
            @apply bg-peach/[.2] border-peach/[.6] text-peach;
        }
    }
}

.contentConseils {
    @apply px-5 md:px-7 lg:px-5 mb-[100px] md:mb-[120px] lg:pt-8 md:mt-10 lg:mt-0;
    &.stickyConseils {
        @apply mb-5 md:mb-12 lg:mb-12;
        + .contentConseils {
            @apply pt-0;
        }
    }
    .titleConseilBlock {
        @apply mb-6;
    }
    .gridConseil {
        @apply grid grid-cols-1 md:grid-cols-2 gap-5 md:gap-6 lg:gap-8 
    }
    &.loading {
        @apply block text-center mt-4;
        svg, i {
            @apply text-[27px] text-peach mb-1;
        }
    }
    &.noResults {
        @apply block text-center mt-12;
        svg {
            @apply text-[35px] text-peach;
        }
        p {
            @apply text-[15px] text-black mt-2 max-w-[250px] leading-tight mx-auto;
        }
    }
}
.cardEvent {
    @apply cursor-pointer;
    .thumbEvent {
        @apply bg-border rounded-xl overflow-hidden mb-3 md:mb-5 relative;
        img {
            @apply aspect-[1.618/1] object-cover w-full h-full;
        }
        .lockedBadge {
            @apply absolute h-full w-full top-0 left-0;
            .lockedBadgeInner {
                @apply absolute flex items-center justify-center w-max top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 bg-white py-3 px-4 rounded-full;
                i, svg {
                    @apply -top-[2px] relative text-[16px] text-peach mr-2;
                }
                span {
                    @apply text-[15px] text-black font-semibold;
                }
            }
        }
        .plannedBadge {
            @apply absolute h-full w-full top-0 left-0;
            .plannedBadgeInner {
                @apply absolute flex items-center justify-center w-max top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 bg-white py-3 px-4 rounded-full;
                i, svg {
                    @apply -top-[2px] relative text-[16px] text-peach mr-2;
                }
                span {
                    @apply text-[15px] text-black font-semibold;
                }
            }
        }
    }
    .titleEvent {
        @apply text-[18px] md:text-[20px] leading-tight mb-0;
    }
    &.locked {
        @apply cursor-auto;
        .thumbEvent img, .titleEvent {
            @apply grayscale opacity-25;
        }
    }
}
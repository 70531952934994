.contentFavoris {
    @apply px-5 md:px-7 lg:px-5 mb-[100px] md:mb-[120px] lg:pt-6;
    &.loading {
        @apply block;
    }
    .titleBlock {
        @apply mb-4 lg:mb-6;
    }
    .contentBlock {
        @apply grid grid-cols-1 md:grid-cols-2 gap-5 md:gap-6 lg:gap-8 mb-10 lg:mb-14;
    }
}


.contentProfil {
    @apply px-5 md:px-7 lg:px-5 mb-[100px] md:mb-[120px];

    .userInfos {
        @apply flex items-center justify-start my-8;
        .avatar {
            @apply mr-3;
            img {
                @apply h-[55px] w-[55px] object-cover object-center rounded-full bg-border;
            }
        }
        .user {
            .name {
                @apply text-[18px] font-semibold mb-0 leading-snug;
            }
            .email {
                @apply text-[14px] text-gray;
            }
        }
    }

    h3 {
        @apply text-[20px] font-medium mb-4 text-peach;
    }


    ul {
        @apply mb-8;
        li{
            @apply cursor-pointer border-t last-of-type:border-b border-border py-3;
            a {
                @apply cursor-pointer;
                &.hasIcon {
                    @apply flex items-center justify-between;
                    svg {
                        @apply text-[14px];
                    }
                }
            }
        }
    }

    .logout {
        @apply mb-6;
    }

    .delete {
        @apply block text-red text-center font-bold text-[14px] tracking-wide uppercase w-full;
    }


}


.pageProfil .boards {
    @media (min-width: 992px) {
        width: calc(100vw - 300px);
        left: 300px;
    }
}
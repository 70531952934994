.rowSeance {
    @apply cursor-pointer flex items-center justify-start p-3 bg-beige rounded-xl mb-4;
    &.viewed {
        @apply bg-skin; 
    }
    .thumbSeance {
        @apply bg-border rounded-lg overflow-hidden mr-3;
        img {
            @apply w-[60px] aspect-[1/1] object-center object-cover;
        }
    }
    .Seancecontent {
        @apply flex-1 mr-3;
        .titleSeance {
            @apply font-medium text-[15px] leading-tight mb-1;
        }
        .dureeSeance {
            @apply flex items-center justify-start text-[14px];
        }
    }
    .viewedNotif {
        @apply ml-auto;
        .viewedNotifInner {
            @apply h-[22px] w-[22px] leading-[23px] text-[11px] text-center rounded-full bg-peach/[.1] border border-peach/[.6] text-peach;
        }
    }
}
.cardConseil {
    @apply cursor-pointer relative overflow-hidden;
    .thumbConseil {
        @apply bg-border rounded-xl overflow-hidden relative;
        img {
            @apply aspect-[1.618/1] object-center object-cover;
        }
        .lockedBadge {
            @apply absolute h-full w-full top-0 left-0;
            .lockedBadgeInner {
                @apply absolute z-[1] flex items-center justify-center w-max top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 bg-white py-3 px-4 rounded-full;
                i, svg {
                    @apply -top-[2px] relative text-[16px] text-peach mr-2;
                }
                span {
                    @apply text-[15px] text-black font-semibold;
                }
            }
        }
    }
    .titleConseil {
        @apply text-[19px] mt-4;
    }
    &.sticky {
        .thumbConseil {
            img {
                @apply aspect-[1/1];
            }
            &:after {
                content: '';
                @apply z-0 top-0 left-0 right-0 bottom-0 h-full w-full bg-gradient-nonetoblack absolute;
            }
        }
        .titleConseil {
            @apply absolute text-[22px] p-5 text-white bottom-0;
        }
    }
    &.locked {
        @apply cursor-auto;
        .thumbConseil img, .titleConseil {
            @apply grayscale opacity-25;
        }
    }
}
.contentSuivi {
    @apply px-5 md:px-7 lg:px-5 mb-[100px] md:mb-[120px];

    .tab-buttons {
        @apply flex mb-5;
        button {
            padding: 14px 18px;
            @apply bg-skin rounded-full relative cursor-pointer text-[15px] text-gray font-medium mr-1;
            &.active {
                @apply bg-peach text-white;
              }
          }
    }
      
    .chapoPage {
        h2 {
            @apply mb-2;
        }
        p {
            @apply font-light;
        }
    }

    .canvasProgression {
        @apply aspect-square max-w-[300px] md:max-w-[400px] bg-gray-light overflow-hidden flex flex-nowrap my-6 mx-auto rounded-lg;

        .canvasBeforeProgression,
        .canvasAfterProgression {
            
            @apply basis-1/2 flex items-center justify-center flex-col bg-gray-light p-4 relative;
            
            .canvasActions {

                @apply flex flex-col items-center justify-center gap-1;

                .label {
                    @apply mb-3 text-gray;
                }

                .chooseImg {
                    @apply relative flex items-center justify-center;
                    .btn-sm {
                        @apply text-[11px] p-2 w-fit;
                    }
                    input {
                        @apply absolute top-0 left-0 right-0 bottom-0 opacity-0;
                    }
                }

            }

            img {
                @apply absolute top-0 left-0 right-0 bottom-0 w-full h-full object-cover object-center;
            }
            .imageActions{
                @apply flex gap-1 justify-self-end h-full;
                .deleteImg {
                    @apply relative z-0 h-[35px] w-[35px] bg-peach text-[13px] text-white leading-[35px] text-center rounded-full mt-auto;
                }
                .reframeImg {
                    @apply relative z-0 h-[35px] w-[35px] bg-white text-[13px] leading-[0px] text-center rounded-full mt-auto;
                }
            }

        }

        .canvasSep {
            @apply bg-body;
        }

        /*
        .canvasBeforeProgression {
            @apply bg-red;
        }
        .canvasAfterProgression {
            @apply bg-green;
        }
        */

        &.portrait {
            @apply flex-row;
            .canvasSep {
                @apply w-[4px] h-full;
            }
        }

        &.paysage {
            @apply flex-col;
            .canvasSep {
                @apply w-full h-[4px];
            }
        }

    }
      
    .switcherDispo {
        @apply flex flex-col m-auto text-center max-w-[250px];
        label, .toggle {
            @apply h-[58px] rounded-full;
        }
        label {
         @apply w-full bg-beige rounded-full relative cursor-pointer;
        }
        .toggle {
            transition: transform .3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
            width: calc(50% - 5px);
            transform: translateX(100%);
            @apply absolute h-[48px] top-[5px] left-[5px] bg-peach;
        }
        .names {
            @apply text-[16px] text-gray font-medium w-full absolute h-full leading-[58px] flex items-center justify-between select-none;
            p {
                @apply flex-1;
                &.paysage {
                    @apply text-white;
                }
            }
        }
        [type="checkbox"] {
            @apply opacity-0 invisible hidden;
        }
        [type="checkbox"]:checked + label .toggle{
            transform: translateX(0%);
        }
        [type="checkbox"]:checked + label .paysage{
            @apply text-gray;
        }
        [type="checkbox"]:checked + label .portrait{
            @apply text-white;
        }
    }

    .poseTracker {
        @apply md:max-w-[600px] overflow-hidden mt-2 mb-6 mx-auto rounded-lg;
        /*
        iframe {
            @apply w-full h-full aspect-square;
        }
        */
        .reset {
            @apply mt-4 text-center w-full text-black font-semibold;
            svg, i {
                @apply text-peach;
            }
        }
    }

    .validateBlock {
        @apply mt-5 md:max-w-[500px] md:mx-auto lg:max-w-[600px] ;
        .chooseFigures {
            @apply h-[60px] w-full bg-white border border-border rounded-lg p-3 mb-4 cursor-pointer;
        }
        .commentaire {
            label {
                span {
                    @apply text-[14px] text-gray ml-1 italic;
                }
                textarea {
                    @apply h-[120px] w-full bg-white border border-border rounded-lg p-3 mb-4 mt-2 cursor-text;
                }
            }
        }
        
        .alert {
            @apply mt-0 mb-4;
        }
        .btn {
            @apply mb-4;
        }
        .autorization {
            @apply text-[13px] text-gray text-center leading-tight mb-2;
        }
        .autorize {
            @apply mb-4;
            label {
                @apply relative text-[15px] block w-fit mb-1 text-center;
                span {
                    @apply relative cursor-pointer;
                    &:before {
                        content:'';
                        @apply w-5 h-5 inline-block relative top-[4px] bg-white border border-border rounded-md mr-2;
                    }
                    &:after {
                        content: '';
                        -webkit-mask: url(/src/assets/check-solid.svg) no-repeat 50% 50%;
                        mask: url(/src/assets/check-solid.svg) no-repeat 50% 50%;
                        -webkit-mask-size: cover;
                        mask-size: cover;
                        @apply w-3 h-3 inline-block top-[3px] left-[4px] absolute opacity-0 invisible;
                    }
                }
                input {
                    @apply absolute top-0 left-0 right-0 bottom-0 opacity-0 invisible;
                }
                &:has( input:checked){
                    span {
                        &:before {
                            @apply bg-peach/[.2] border-peach/[.6];
                        }
                        &:after {
                            @apply opacity-100 visible bg-peach;
                        }
                    }
                }
            }

        }
    }

    .loading {
        @apply text-center mt-8;
        svg, i {
            @apply text-[27px] text-peach mb-1;
        }
        span {
            @apply block;
        }
    }

    .error-message, .success-message{
        @apply py-3;
        svg, i {
            @apply text-[20px] mb-2;
        }
        span {
            @apply block text-[15px];
        }
    }

    .info-message {
        svg, i {
            @apply mr-2;
        }
    }


    .userProgressions {
        @apply mt-8 pt-8 border-t border-border;
        .topUserProgressions {
            @apply flex items-center justify-between mb-6;
            h2 {
                @apply text-[20px];
            }
            .chooseFigures {
                @apply h-[30px] w-fit bg-white border border-border rounded-md px-1 text-[12px] text-gray focus:outline-none;
            }
        }
        .galleryUserProgressions {
            @apply grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5 md:gap-6 lg:gap-8;
            &.noResult,
            &.loading {
                @apply block text-center mt-10;
            }
            .pogressionCard {
                @apply cursor-pointer;
                .imgProgressionCard {
                    @apply aspect-square w-full object-center object-cover rounded-lg bg-border;
                }
                .figureProgressionCard {
                    @apply text-[15px] mt-2;
                }
                .dateProgressionCard {
                    @apply text-[13px] text-gray;
                }
            }

        }
    }

}



.modalShowProgression {
    @apply fixed w-screen h-dvh top-0 right-0 bottom-0 left-0 p-6 flex items-center justify-center bg-black/[.8] backdrop-blur-sm z-50;

    @media (min-width: 992px) {
        width: calc(100vw - 300px);
        left: 300px;
    }
    
    .closeModalProgression {
        @apply fixed top-4 right-4 text-[30px] text-white;
    }

    .modalInnerShowProgression {
        @apply bg-white w-full overflow-y-scroll max-h-[85dvh] mt-8 rounded-2xl p-6 h-auto min-h-[50%] md:w-[60%] lg:w-[50%] xl:w-[40%] 2xl:w-[30%];
        h3 {
            @apply text-[20px] font-medium mb-3;
        }
        .figure {
            @apply text-[18px];
        }
        .date {
            @apply text-[15px] text-gray;
        }
        .montageProgression {
            @apply my-6 aspect-square w-full bg-border object-center object-cover rounded-lg;
        }
        .commentaireFlexifit {
            @apply mb-6;
            svg, i {
                @apply text-peach mr-2 relative -top-[3px];
            }
            p {
                @apply italic;
            }
        }
        .delete {
            @apply mt-2 border-red bg-red text-white;
        }
        .alert {
            @apply text-[14px] text-center mb-3;
        }
    }

}



.amplitudesModal {
    .modalInnerShowProgression {
        @apply md:w-[70%] lg:w-[80%] xl:w-[70%] 2xl:w-[50%];
    }
    .userProgressions{
        @apply mt-0 pt-0 border-t-0;
        .galleryUserProgressions {
            @apply grid grid-cols-2 lg:grid-cols-3 gap-5;
        }
    }
}



.no-scroll {
    overflow: hidden !important;
    position: fixed;
    width: 100%;
    height: 100%;
    touch-action: none;
    -webkit-overflow-scrolling: none;
    overscroll-behavior: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
.contentAccueil.event {
    @apply -mt-6 md:mt-0 border-t md:border-none border-body py-0 px-0 md:px-7 md:py-5 lg:px-5 mb-10 md:mb-3;
    .eventUne {
        @apply bg-skin md:rounded-xl py-5 px-5 md:px-7 lg:py-8 lg:px-8;
    }
}

.contentAccueil {
    @apply px-5 md:px-7 lg:px-5 mb-[100px] md:mb-[120px] lg:pt-8;
    .programmeUne {
        @apply mb-10;
        .cardProgramme {
            @apply md:flex;
            .thumbProgramme {
                @apply md:w-[350px] lg:w-[450px] md:mb-0;
                img {
                    @apply aspect-card;
                }
            }
            .contentProgrammeInner {
                @apply md:ml-8 lg:ml-10 md:my-8 md:flex-1;
                .titleProgramme {
                    @apply text-[25px];
                }
                .descriptionProgramme {
                    @apply mt-4;
                    * {
                        @apply text-[15px];
                    }
                }
                .seeMore {
                    @apply block mt-2 font-medium text-[15px];
                }
            }
        }
    }

    .titleUne {
        @apply mb-4 text-[20px] font-semibold;
    }

    .slidesBlock {
        @apply mb-10;
        .rowSlide {
            @apply mb-10 last-of-type:mb-0;
            .rowSlideInner {
                width: calc(100% + 2.5rem);
                @apply relative flex gap-4 snap-x snap-mandatory overflow-x-auto -ml-5 md:-ml-7 lg:-ml-5 px-5 md:px-7 lg:px-5;
                .cardSlide {
                   @apply snap-start scroll-mx-5 md:scroll-mx-7 lg:scroll-mx-5 shrink-0 w-[40%] md:w-[30%] lg:w-[30%] lg:max-w-[300px]; 
                   img {
                    @apply shrink-0 rounded-lg w-full aspect-card object-cover object-center bg-border;
                   }
                   p {
                    @apply mt-2 text-[15px] font-medium;
                   }
                }
                .cardProgramme {
                    @apply snap-start scroll-mx-5 md:scroll-mx-7 lg:scroll-mx-5 shrink-0 w-[90%] md:w-[47%] lg:w-[40%] lg:max-w-[450px]; 
                }
            }
        }
    }

    .bannerSuivi {
        @apply mb-10 md:my-16;
        .bannerSuiviInner {
            @apply bg-skin rounded-2xl p-6 lg:p-10 flex flex-col md:flex-row md:items-center md:max-w-[650px] lg:max-w-[800px] md:mx-auto;
            .imgSide {
                @apply mb-4 md:mb-0;
                img {
                    @apply h-[175px] mx-auto;
                }
            }
            .contentSide {
                @apply text-center md:flex-1 md:ml-6 lg:ml-10 md:text-left;
                h3 {
                    @apply text-[20px] leading-tight font-medium mb-2;
                    svg, i {
                        @apply mr-2;
                    }
                }
                p {
                    @apply text-[16px] leading-tight;
                }
                .btn { 
                    @apply mt-4 w-full;
                }
            }
        }
    }

    .conseilsUne {
        .conseilsUneContent {
            @apply grid grid-cols-1 md:grid-cols-2 gap-5 md:gap-6 lg:gap-8;
        }
    }
}
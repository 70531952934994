.topThumbProgramme {
    @apply relative bg-border overflow-hidden aspect-square md:aspect-video lg:aspect-[16/6];
    img {
        @apply w-full h-full object-center object-cover;
    }
    &::after {
        content: '';
        @apply block absolute -bottom-[1px] left-0 bg-linear-gradient-v w-full h-[20%];
    }
    &.Desktop {
        @apply hidden md:block;
    }
    &.Mobile {
        @apply block md:hidden;
    }
}

.contentProgramme {
    @apply px-5 md:px-7 lg:px-0 mb-[100px] md:mb-[120px];

    .cta {
        @apply -mt-6 mb-4 z-10 relative;
    }
    
    h1 {
        @apply text-[24px] leading-tight mb-1;
    }
    
    .infosProgramme {
        @apply flex items-center justify-start text-gray text-[15px];
        .seanceProgramme {
            @apply mr-4;
        }
        .levelProgramme {
            @apply flex items-center;
            .level {
                @apply flex items-end mr-1 mb-[1px];
                span{
                    @apply bg-black/[.3] mr-[2px] w-[3px] h-[10px];
                    &:nth-of-type(1) {
                        @apply h-[5px];
                    }
                    &:nth-of-type(2) {
                        @apply h-[8px];
                    }
                    &:nth-of-type(3) {
                        @apply h-[10px];
                    }
                }
                &.debutant {
                    span:nth-of-type(1) {
                        @apply bg-black;
                    }
                }
                &.intermediaire {
                    span:nth-of-type(1),
                    span:nth-of-type(2) {
                        @apply bg-black;
                    }
                }
                &.confirme,
                &.avance {
                    span:nth-of-type(1),
                    span:nth-of-type(2),
                    span:nth-of-type(3) {
                        @apply bg-black;
                    }
                }
            }
            p {
                line-height: 1em;
            }
        }
    }
    
    .startProgramme {
        @apply mt-4;
    }

    .btnPinProgramme {
        @apply mt-2 border-peach/25;
    }
    
    .requiredBlock {
        @apply mt-5;
    }
    
    .required {
        @apply mb-5;
        .label {
            @apply mb-2 text-[15px];
        }
        .pills {
            @apply flex flex-wrap items-start justify-start;
            .pill {
                @apply bg-skin py-[5px] px-[8px] rounded text-[13px] mr-2 mb-2;
            }
        }
    }

    .btn-conseil {
        &.locked {
            @apply grayscale opacity-75 cursor-auto;
            span {
                @apply block text-[11px];
                i, svg {
                    @apply mr-1;
                }
            }
        }
    }
    
    .descriptionProgramme {
        @apply text-[15px];
    }
    
    .player-wrapper {
        @apply my-6;
        .title {
            @apply text-[20px] mb-4;
        }
        div {
            @apply !w-full !h-full;
            iframe {
                @apply aspect-video rounded-lg;
            }
        }
    }
    
    #composition {
        .slot {
            @apply border-t border-border mt-6 pt-6;
            .label {
                @apply text-[18px] font-medium mb-4;
            }
        }
    }


    .bannerSuivi {
        @apply my-12 md:my-16;
        .bannerSuiviInner {
            @apply bg-skin rounded-2xl p-6 lg:p-10 flex flex-col md:flex-row md:items-center md:max-w-[650px] lg:max-w-[800px] md:mx-auto;
            .imgSide {
                @apply mb-4 md:mb-0;
                img {
                    @apply h-[175px] mx-auto;
                }
            }
            .contentSide {
                @apply text-center md:flex-1 md:ml-6 lg:ml-10 md:text-left;
                h3 {
                    @apply text-[20px] leading-tight font-medium mb-2;
                    svg, i {
                        @apply mr-2;
                    }
                }
                p {
                    @apply text-[16px] leading-tight;
                }
                .btn { 
                    @apply mt-4 w-full;
                }
            }
        }
    }
    
}


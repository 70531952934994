.MuiBottomNavigation-root {
    @apply w-screen fixed bottom-0 left-0 right-0 !h-[75px] md:!h-[85px] drop-shadow-2xl z-10;
    .MuiButtonBase-root {
        @apply opacity-30;
        &.Mui-selected {
            @apply opacity-100;
        }
        img {
            @apply mb-1;
        }
        .MuiBottomNavigationAction-label {
            @apply uppercase text-black text-[10px] font-bold font-nunito;
        }
    }
}

.MuiPaper-root {
    .MuiList-root {
        @apply h-screen flex flex-col py-8;
        .MuiListItem-root {
            @apply px-0 py-2 opacity-30;
            &.active {
                @apply opacity-100;
            }
            .MuiButtonBase-root {
                @apply px-6 py-2 flex justify-start items-center;
                .MuiListItemIcon-root {
                    @apply min-w-[25px] mr-4;
                }
                .MuiListItemText-root {
                    @apply m-0;
                    .MuiTypography-root {
                        @apply uppercase text-black text-[16px] font-bold font-nunito;
                    }
                }
            }
        }
        .actionsList {
            @apply mt-auto px-6;
            .favorisBtn {
                @apply py-2 flex justify-start items-center cursor-pointer opacity-30;
                .Icon {
                    @apply min-w-[25px] mr-4 text-[23px];
                }
                .Label {
                    @apply m-0 uppercase text-black text-[16px] font-bold font-nunito;
                }
            }
            .profilBtn {
                @apply py-2 flex justify-start items-center cursor-pointer;
                .IconProfil {
                    @apply mr-4;
                }
                .Label {
                    @apply m-0 uppercase text-black text-[14px] font-bold font-nunito;
                }
            }
            .logout {
                @apply mt-4 cursor-pointer;
            }
        }
    }
}
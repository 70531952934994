.topThumbConseil {
    @apply relative bg-border overflow-hidden aspect-square md:aspect-video lg:aspect-[16/6];
    img {
        @apply w-full h-full object-center object-cover;
    }
    &::after {
        content: '';
        @apply block absolute -bottom-[1px] left-0 bg-linear-gradient-v w-full h-[20%];
    }
    &.Desktop {
        @apply hidden md:block;
    }
    &.Mobile {
        @apply block md:hidden;
    }
}

.contentConseil {
    @apply px-5 md:px-7 lg:px-5 mb-[100px] md:mb-[120px];
    
    + .contentProgrammes {
        @apply -mt-[60px] block;
        .titleProgrammeBlock {
            @apply mb-6;
        }
        .gridProgrammes {
            @apply grid grid-cols-1 md:grid-cols-2 gap-5 md:gap-6 lg:gap-8;
        }
    }

    h1 {
        @apply -mt-6 mb-4 z-10 text-[24px] relative leading-tight md:max-w-[85vw] md:mx-auto lg:mx-0 lg:max-w-full;
    }

    .blockTextRendered {
        @apply md:max-w-[85vw] md:mx-auto lg:mx-0 lg:max-w-[950px];
    }

    .btn {
        @apply mt-4 md:max-w-[85vw] md:mx-auto lg:mx-0 lg:max-w-[950px];
    }
}




.cardProgramme {
    @apply cursor-pointer;
    .thumbProgramme {
        @apply bg-border rounded-xl overflow-hidden mb-3 relative;
        img {
            @apply aspect-[1.618/1] object-center object-cover;
        }
        .lockedBadge {
            @apply absolute h-full w-full top-0 left-0;
            .lockedBadgeInner {
                @apply absolute flex items-center justify-center w-max top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 bg-white py-3 px-4 rounded-full;
                i, svg {
                    @apply -top-[2px] relative text-[16px] text-peach mr-2;
                }
                span {
                    @apply text-[15px] text-black font-semibold;
                }
            }
        }
        .plannedBadge {
            @apply absolute h-full w-full top-0 left-0;
            .plannedBadgeInner {
                @apply absolute flex items-center justify-center w-max top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 bg-white py-3 px-4 rounded-full;
                i, svg {
                    @apply -top-[2px] relative text-[16px] text-peach mr-2;
                }
                span {
                    @apply text-[15px] text-black font-semibold;
                }
            }
        }
        .progressionProgramme {
            @apply absolute top-4 right-4 bg-white py-2 px-3 rounded-full flex items-center justify-start;
            .progressBar {
                @apply block mr-2 h-[4px] w-[40px] rounded-full bg-border relative overflow-hidden;
                &.active {
                    @apply absolute m-0 h-full bg-peach top-0 left-0;
                }
            }
            p {
                @apply text-[10px] leading-tight;
            }
        }
    }
    .titleProgramme {
        @apply text-[18px] leading-tight mb-1;
    }
    .infosProgramme {
        @apply flex items-center justify-start text-gray text-[14px];
        .seanceProgramme {
            @apply mr-4;
        }
        .levelProgramme {
            @apply flex items-center;
            .level {
                @apply flex items-end mr-1 mb-[1px];
                span{
                    @apply bg-black/[.3] mr-[2px] w-[3px] h-[10px];
                    &:nth-of-type(1) {
                        @apply h-[5px];
                    }
                    &:nth-of-type(2) {
                        @apply h-[8px];
                    }
                    &:nth-of-type(3) {
                        @apply h-[10px];
                    }
                }
                &.debutant {
                    span:nth-of-type(1) {
                        @apply bg-black;
                    }
                }
                &.intermediaire{
                    span:nth-of-type(1),
                    span:nth-of-type(2) {
                        @apply bg-black;
                    }
                }
                &.confirme,
                &.avance {
                    span:nth-of-type(1),
                    span:nth-of-type(2),
                    span:nth-of-type(3) {
                        @apply bg-black;
                    }
                }
            }
            p {
                line-height: 1em;
            }
        }
    }
    &.locked {
        @apply cursor-auto;
        .thumbProgramme img, .progressionProgramme, .titleProgramme, .infosProgramme {
            @apply grayscale opacity-25;
        }
    }
}
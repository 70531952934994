.boards {
    @apply z-50 fixed w-screen h-screen bg-body flex justify-start items-center flex-col lg:flex-row;
    .img-side {
        @apply w-full h-[62vh] lg:h-screen w-screen lg:flex-1 bg-peach relative overflow-hidden;
        &::after {
            content: '';
            @apply block absolute -bottom-[1px] left-0 bg-linear-gradient-v w-full h-[35vh] lg:hidden;
        } 
    }
    .content-side {
        @apply p-5 pb-10 lg:p-12 mt-auto lg:flex-1;
        .content-inner {
            @apply md:max-w-[550px] lg:max-w-[600px] mx-auto; 
        }
    }
    .dotted {
        display: flex;
        align-items: center;
        justify-content: center;
        .dot {
            @apply h-[6px] w-[22px] bg-beige mx-[4px] rounded-full;
            &.active {
                @apply w-[44px] bg-peach;
            }
        }
    }
}
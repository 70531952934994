.cardSeance {
    @apply cursor-pointer;
    .thumbSeance {
        @apply bg-border rounded-xl overflow-hidden mb-3 relative;
        img {
            @apply aspect-[1.618/1] object-center object-cover;
        }
        .lockedBadge {
            @apply absolute h-full w-full top-0 left-0;
            .lockedBadgeInner {
                @apply absolute flex items-center justify-center w-max top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 bg-white py-3 px-4 rounded-full;
                i, svg {
                    @apply -top-[2px] relative text-[16px] text-peach mr-2;
                }
                span {
                    @apply text-[15px] text-black font-semibold;
                }
            }
        }
        .plannedBadge {
            @apply absolute h-full w-full top-0 left-0;
            .plannedBadgeInner {
                @apply absolute flex items-center justify-center w-max top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 bg-white py-3 px-4 rounded-full;
                i, svg {
                    @apply -top-[2px] relative text-[16px] text-peach mr-2;
                }
                span {
                    @apply text-[15px] text-black font-semibold;
                }
            }
        }
    }
    .titleSeance {
        @apply text-[18px] leading-tight mb-2;
    }
    .infosSeance {
        @apply flex items-center justify-start text-gray text-[14px];
        .dureeSeance {
            @apply flex items-center mr-4;
            p {
                line-height: 1em;
            }
        }
        .levelSeance {
            @apply flex items-center mr-4;
            .level {
                @apply flex items-end mr-1 mb-[1px];
                span{
                    @apply bg-black/[.3] mr-[2px] w-[3px] h-[10px];
                    &:nth-of-type(1) {
                        @apply h-[5px];
                    }
                    &:nth-of-type(2) {
                        @apply h-[8px];
                    }
                    &:nth-of-type(3) {
                        @apply h-[10px];
                    }
                }
                &.debutant {
                    span:nth-of-type(1) {
                        @apply bg-black;
                    }
                }
                &.intermediaire {
                    span:nth-of-type(1),
                    span:nth-of-type(2) {
                        @apply bg-black;
                    }
                }
                &.confirme,
                &.avance {
                    span:nth-of-type(1),
                    span:nth-of-type(2),
                    span:nth-of-type(3) {
                        @apply bg-black;
                    }
                }
            }
            p {
                line-height: 1em;
            }
        }
        .intensiteSeance {
            @apply flex items-center;
            .intensite {
                @apply flex items-end mr-1 mb-[1px];
                svg {
                    @apply text-black/[.3] text-[13px] mr-[2px]
                }
                &.faible {
                    svg:nth-of-type(1) {
                        @apply text-black;
                    }
                }
                &.moyenne {
                    svg:nth-of-type(1),
                    svg:nth-of-type(2) {
                        @apply text-black;
                    }
                }
                &.fort,
                &.elevee {
                    svg:nth-of-type(1),
                    svg:nth-of-type(2),
                    svg:nth-of-type(3) {
                        @apply text-black;
                    }
                }
                &.recuperation {
                    svg {
                        @apply text-black;
                    }
                }
            }
            p {
                line-height: 1em;
            }
        }
    }
    &.locked {
        @apply cursor-auto;
        .thumbSeance img, .titleSeance, .infosSeance {
            @apply grayscale opacity-25;
        }
    }
}
.topThumbSeance {
    @apply relative bg-border overflow-hidden aspect-square md:aspect-video lg:aspect-[16/6];
    img {
        @apply w-full h-full object-center object-cover;
    }
    &::after {
        content: '';
        @apply block absolute -bottom-[1px] left-0 bg-linear-gradient-v w-full h-[20%];
    }
    &.Desktop {
        @apply hidden md:block;
    }
    &.Mobile {
        @apply block md:hidden;
    }
}

.contentSeance {
    @apply px-5 md:px-7 lg:px-0 mb-[100px] md:mb-[120px];

    .cta {
        @apply -mt-6 mb-4 z-10 relative;
    }
    
    h1 {
        @apply text-[24px] leading-tight mb-2;
    }

    .action {
        .label {
            @apply mb-0;
        }
        .sublabel {
            @apply mt-1;
        }
    }
    
    .infosSeance {
        @apply flex items-center justify-start text-gray text-[15px];
        .levelSeance {
            @apply flex items-center mr-4;
            .level {
                @apply flex items-end mr-1 mb-[1px];
                span{
                    @apply bg-black/[.3] mr-[2px] w-[3px] h-[10px];
                    &:nth-of-type(1) {
                        @apply h-[5px];
                    }
                    &:nth-of-type(2) {
                        @apply h-[8px];
                    }
                    &:nth-of-type(3) {
                        @apply h-[10px];
                    }
                }
                &.debutant {
                    span:nth-of-type(1) {
                        @apply bg-black;
                    }
                }
                &.intermediaire {
                    span:nth-of-type(1),
                    span:nth-of-type(2) {
                        @apply bg-black;
                    }
                }
                &.confirme,
                &.avance {
                    span:nth-of-type(1),
                    span:nth-of-type(2),
                    span:nth-of-type(3) {
                        @apply bg-black;
                    }
                }
            }
            p {
                line-height: 1em;
            }
        }
        .intensiteSeance {
            @apply flex items-center;
            .intensite {
                @apply flex items-end mr-1 mb-[1px];
                svg {
                    @apply text-black/[.3] text-[13px] mr-[2px]
                }
                &.faible {
                    svg:nth-of-type(1) {
                        @apply text-black;
                    }
                }
                &.moyenne {
                    svg:nth-of-type(1),
                    svg:nth-of-type(2) {
                        @apply text-black;
                    }
                }
                &.fort,
                &.elevee {
                    svg:nth-of-type(1),
                    svg:nth-of-type(2),
                    svg:nth-of-type(3) {
                        @apply text-black;
                    }
                }
                &.recuperation {
                    svg {
                        @apply text-black;
                    }
                }
            }
            p {
                line-height: 1em;
            }
        }
    }
    
    .requiredBlock {
        @apply mt-5;
    }
    
    .required {
        @apply mb-5;
        .label {
            @apply mb-2 text-[15px];
        }
        .pills {
            @apply flex flex-wrap items-start justify-start;
            .pill {
                @apply bg-skin py-[5px] px-[8px] rounded text-[13px] mr-2 mb-2;
            }
        }
    }
    
    .descriptionSeance {
        @apply text-[15px];
    }
    
    .player-wrapper {
        @apply my-6;
        .btnAvancement {
            @apply mt-8 mb-2 text-[14px];
            padding: 12px 16px;
        }
        .title {
            @apply text-[20px] mb-4 mt-8;
        }
        div {
            @apply !w-full !h-full;
            iframe {
                @apply aspect-video rounded-lg !w-full !h-full;
            }
        }
    }
    
    .coolDown {
        @apply border-t border-border mt-8 mb-8 pt-8;
        .topCoolDown {
            @apply flex flex-wrap items-end justify-between lg:justify-start;
            h2 {
                @apply text-[20px] font-medium m-0;
            }
            .timeCoolDown {
                @apply text-[13px] mb-0 lg:ml-2 leading-none relative -top-[3px];
            }
        }
    }
    
}


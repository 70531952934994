.contentProgrammes {
    @apply px-5 md:px-7 lg:px-5 mb-[100px] md:mb-[120px] grid grid-cols-1 md:grid-cols-2 gap-5 md:gap-6 lg:gap-8 lg:pt-8;
    &.loading {
        @apply block text-center mt-4;
        svg, i {
            @apply text-[27px] text-peach mb-1;
        }
    }
    &.noResults {
        @apply block text-center mt-12;
        svg {
            @apply text-[35px] text-peach;
        }
        p {
            @apply text-[15px] text-black mt-2 max-w-[250px] leading-tight mx-auto;
        }
    }
}
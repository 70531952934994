.input-row {
    margin-bottom: 1.5rem;
    label {
        margin-bottom: 4px;
        @apply font-medium block;
    }
    div {
        position: relative;
        input {
            padding: 16px 45px 16px 18px;
            @apply bg-white border border-border rounded-[10px] w-full focus:outline-none focus:border-borderfocused;
        }
        .validate {
            position: absolute;
            top: 50%;
            right: 1rem;
            transform: translateY(-50%);
            font-size: 20px;
            line-height: 20px;
            &.showhide {
                font-size: 15px;
                line-height: 15px;
                @apply text-gray;
            }
        }
        input[type="email"][value=""], input[type="email"]:not([value]) {
            padding: 16px 18px;
            & + .validate {
                display: none;
            }
        }
    }
}

.loginform {
    .lostpassword {
        margin-top: -.5rem;
        font-size: .9em;
    }
    button.btn {
        margin-top: 1.5rem;
        svg {
            @apply text-[16px] top-[1px] ml-1 relative;
        }
    }
}